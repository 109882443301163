<template>
<footer id="footer">
    <div class="btmFooter">
        <div class="container">
            <div class="col-sm-12 text-center">
                <ul class="row pay-opt list-inline text-center d-flex justify-content-center" style="padding: 0 20px;">
                    <!-- Grid column -->
                    <div class="col-md-1 ">
                    </div>
                    <div class="col-md-2 ">
                        <h6 class="text-uppercase ">
                            <router-link to="/" class="footer-menu-text"><i class="ti-home"></i> <span> Home </span></router-link>
                        </h6>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-2 ">
                        <h6 class="text-uppercase ">
                            <router-link to="/coupon" class="footer-menu-text"><i class="ti-cut"></i> <span> Coupons </span></router-link>
                        </h6>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-2 ">
                        <h6 class="text-uppercase ">
                            <router-link to="/privacy-policy" class="footer-menu-text"><i class="ti-receipt"></i> <span> PrivacyPolicy </span></router-link>
                        </h6>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-2 ">
                        <h6 class="text-uppercase ">
                            <router-link to="/contact" class="footer-menu-text"><i class="ti-email"></i> <span> Contact </span></router-link>
                        </h6>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-2 ">
                        <h6 class="text-uppercase ">
                            <router-link to="/about" class="footer-menu-text"><i class="ti-layout-list-thumb"></i> <span> About </span></router-link>
                        </h6>
                    </div>
                    <!-- Grid column -->
                    <div class="col-md-1 ">
                    </div>
                </ul>
            </div>
            <div class="col-sm-12 text-center m-b-20">
                <hr>

                <p v-if="$store.state.coupon.footer">
                    <strong>Copyright {{ new Date().getFullYear() }}</strong> - <a href="https://bisharafak.com" class="text-dark">Bisharafak.com</a>
                </p>
                <!-- <p v-html="footerText">
                </p> -->
            </div>

        </div>
    </div>
</footer>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        footerText() {
            if (this.$store.state.coupon.footer.hasOwnProperty('footerConfig') &&
                this.$store.state.coupon.footer.footerConfig.hasOwnProperty('footer_text')
            ) {
                return this.$store.state.coupon.footer.footerConfig.footer_text;
            }
            return ''
        }
    }
};
</script>

<style scoped>
.footer-menu-text {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #212121;
}
</style>
